<template>
  <div>
    <AddBloodActivity title="Penyileran" processActivity="addProcessSieving" />
  </div>
</template>

<script>
import AddBloodActivity from "../components/AddBloodActivity.vue";

export default {
  name: "Penyileran",
  components: {
    AddBloodActivity,
  },
};
</script>
